<template>
  <div class="mt-5">
    <v-container v-if="editLoader" type="image" class="loding">
      <v-skeleton-loader type="image"></v-skeleton-loader>
      <v-skeleton-loader type="image"></v-skeleton-loader>
    </v-container>

    <div v-else class="modal-test my-5 mx-3 bg-color p-5">
      <div>
        <div class="d-flex justify-content-between mb-5">
          <v-chip
            class="py-4 px-4 brouillon"
            v-if="devisObject.data.brouillon === 1"
            style="background: #e4261b; color: white"
          >
            {{ $t("DRAFT") }}
          </v-chip>
          <v-chip
            class="py-3 px-4 brouillon"
            v-if="devisObject.data.brouillon === 0"
            style="background: #f8f8f8"
          >
            {{ $t("SIGNA") }}
          </v-chip>
          <div>
            <b-dropdown
              size="lg"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="custom-dropdown"
            >
              <template #button-content style="box-shadow: none !important">
                <span style="color: black; font-size: 24px">&#x22EE;</span>
                <span class="sr-only">Menu</span>
              </template>
              <b-dropdown-item
                v-if="
                  isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isCommercial
                "
                @click="generate()"
                >Telecharger</b-dropdown-item
              >
              <b-dropdown-item href="#">facture</b-dropdown-item>
              <b-dropdown-item
                v-if="
                  isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isCommercial
                "
                @click="sendEmail()"
              >
                {{ $t("SEND_BY_EMAIL") }}</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isCommercial
                "
                @click="signed()"
                >{{ $t("YOUSIGN") }}</b-dropdown-item
              >
            </b-dropdown>
            <b-button
              variant="success"
              @click="handleUpdate()"
              v-if="
                isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
              "
            >
              {{ $t("EDIT") }}
              <div v-if="editLoader" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </b-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8 d-flex">
          <div>
            <b>{{ $t("ESTIMATE") }} </b>
          </div>
          <span class="info">N° {{ devisObject.data.devis?.numerodevis }}</span>
        </div>
        <div class="col-4 d-flex mb-1">
          <span class="info d-none d-md-block">
            {{ devisObject.data.devis?.client?.nom }}
            {{ devisObject.data.devis?.client?.prenom }}</span
          >
        </div>
      </div>
      <div class="part justify">
        <div class="divborder"></div>
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12">
            <div class="block">
              <div class="d-flex mb-1">
                <span class="title"
                  >{{ $t("Numéro client") }}:{{
                    devisObject.data.devis?.client?.id
                  }}
                </span>
              </div>
              <div class="d-flex mb-1">
                <span class="title">{{ $t("DATE_VISITE_TECHNIQUE") }}:</span>
                <span> {{ devisObject.data.devis?.visite_technique }}</span>
              </div>
              <div class="d-flex mb-1">
                <span class="title">{{ $t("DATE_DEVIS") }}:</span>
                <span> {{ devisObject.data.devis?.debut_devis }} </span>
              </div>
              <div class="d-flex mb-1">
                <span class="title">{{ $t("DATE-FIN") }}:</span>
                <span> {{ devisObject.data.devis?.fin_devis }} </span>
              </div>
              <div class="d-flex mb-1">
                <span class="title">{{ $t("Adresse des travaux") }}:</span>
                <span>
                  {{ devisObject.data.devis?.rue_travaux }}
                  {{ devisObject.data.devis?.ville_travaux }}
                  {{ devisObject.data.devis?.cp_travaux }}
                </span>
              </div>
              <div>
                Parcelle cadastrale:
                {{ devisObject.data.devis?.logement?.parcelle_cadastrale }}
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <ul>
              <span class="info mb-1">
                {{ devisObject.data.devis?.client?.rue }}
              </span>
              <span class="info mb-1">
                {{ devisObject.data.devis?.client?.cp }}
                {{ devisObject.data.devis?.client?.ville }}
              </span>
              <div class="d-flex">
                <span class="entrepriseinfo">{{ $t("TEL") }} : </span>
                <span>
                  {{ devisObject.data.devis?.client?.phone.prefix }}
                  {{ devisObject.data.devis?.client?.phone.phone_number }}
                </span>
              </div>
              <div class="d-flex">
                <span class="entrepriseinfo">{{ $t("ZONE") }} : </span>
                <span>{{ devisObject.data.devis?.client?.zone }} </span>
              </div>

              <div class="d-flex">
                <span class="entrepriseinfo"> Précatité : </span>
                <span>{{ devisObject.data.devis?.client?.precarite }} </span>
              </div>
              <div>
                <span class="entrepriseinfo"
                  >{{ $t("Type de chauffage") }} :
                </span>
                <span>
                  {{ devisObject.data.devis?.logement?.type_chauffage }}
                </span>
              </div>
              <div>
                <span class="entrepriseinfo"
                  >{{ $t("Type de logement") }} :
                </span>
                <span> {{ devisObject.data.devis?.logement?.type }} </span>
                <span
                  >/ +
                  {{ devisObject.data.devis?.logement?.ancienter }} ans</span
                >

                <span>/{{ devisObject.data.devis?.logement?.surface }} m²</span>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="divborder mb-3"></div>
      <div class="d-flex">
        <div class="flex-grow-1">
          <b>{{ $t("DETAILS_DEVIS") }} </b>
        </div>
        <div class="flex-grow-1">
          <div class="d-flex justify-content-around">
            <div>
              <b>{{ $t("QTE") }} </b>
            </div>
            <div>
              <b>{{ $t("PUHT") }}</b>
            </div>
            <div>
              <b>{{ $t("TOTALHT") }}</b>
            </div>
            <div>
              <b>{{ $t("VAT") }}</b>
            </div>
          </div>
        </div>
      </div>
      <div class="divborder mb-3"></div>
      <div>
        BAR-TH-101 : Mis en page d'un chauffe-eau solaire individuel(CESI),
        Marque <b> {{ devisObject.data.devis?.chauffe_eau?.marque }}</b
        >, Référence
        <b> {{ devisObject.data.devis?.chauffe_eau?.reference }}</b>
      </div>
      <div>
        Les capteurs solaire ont une certification
        <b>{{ devisObject.data.devis?.chauffe_eau?.nom_certification }}</b>
        reposant sur la norme
        <b> {{ devisObject.data.devis?.chauffe_eau?.norme }}</b
        >.
      </div>
      <div>
        La nature du fluide circulant dans les capteurs :<b>{{
          devisObject.data.devis?.chauffe_eau?.nature_fluide
        }}</b>
      </div>
      <div>
        <b>Note : Les capteurs solaires ne sont pas des capteurs hybrides.</b>
      </div>
      <div>
        - Kwh Cumac : <b>{{ devisObject.data.devis?.cumac }} </b>
      </div>
      <div>
        - Prime CEE <b> {{ devisObject.data.devis.montant_cee }} € </b>
      </div>
      <div
        v-if="devisObject.data.groupeLigneDocument[0]?.sous_traitant !== null"
      >
        Matériel(s) fourni(s) et mis en place par le sous traitant
        <b>
          {{ devisObject.data.groupeLigneDocument[0]?.sous_traitant?.name }}</b
        >
      </div>
      <div v-else>
        Matériel(s) fourni(s) et mis en place par notre société
        <b> {{ devisObject.data.devis.entreprise?.lib }}</b>
      </div>

      <div
        v-if="devisObject.data.groupeLigneDocument[0]?.sous_traitant !== null"
      >
        représentée par
        {{ devisObject.data.groupeLigneDocument[0]?.sous_traitant?.name }} ,
        SIRET
        <b>
          {{
            devisObject.data.groupeLigneDocument[0]?.sous_traitant?.num_siret
          }}
          ,</b
        >
        Certificat rge
      </div>
      <div v-else>
        représentée par
        {{ devisObject.data.devis.entreprise?.lib }} , SIRET
        <b>
          {{ devisObject.data.devis.entreprise?.siret }}
          ,</b
        >
        Certificat rge
      </div>
      <div>
        Numéro
        <b> {{ devisObject.data.devis?.certificat_rge?.num }} </b> attribué le
        <b>{{ devisObject.data.devis?.certificat_rge?.date_attribution }}</b>
        valable jusqu'au
        <b> {{ devisObject.data.devis?.certificat_rge?.date_expiration }}</b
        >, Assurance
      </div>
      <div>
        civile <b> N° {{ devisObject.data.devis.entreprise?.num_assurance }}</b>
      </div>
      <ul>
        <li>
          Date de la visite technique :<b>
            {{ devisObject.data.devis.visite_technique }}</b
          >
        </li>
      </ul>
      <div>
        <div
          v-for="(produit, idx) in devisObject.data.GroupeLigneDocument_produit"
          :key="idx"
          class="row mb-2"
        >
          <div class="col-md-6">
            <div>
              <b>{{ produit.lib }}</b>
            </div>
            <p>{{ produit.desc }}</p>
          </div>

          <div class="col-md-6">
            <div class="row text-center">
              <div class="col">
                <b>{{ produit.quantite }} u</b>
              </div>
              <div class="col">
                <b>{{ produit.unit_price }}</b
                ><b>{{ produit.type_unite }}</b>
              </div>
              <div class="col">
                  
                <b> {{ parseFloat(produit.quantite) * parseFloat(produit.unit_price) }} €</b>
              </div>

              <div class="col">
                <b>{{ produit.tva }}%</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="part"></div>
      </div>

      <div class="part">
        <div><b>Termes et conditions CEE</b></div>
        <p>
          Les travaux ou prestations objet du présent document donneront lieu à
          une contribution financiére de
          {{ devisObject.data.devis?.partenaire?.nom }} (SIRET
          {{ devisObject.data.devis?.partenaire?.siret }}), versée par
          {{ devisObject.data.devis?.partenaire?.nom }} dans le cadre de son
          role actif et incitatif sous forme, directement ou via son mondataire
          {{ devisObject.data.devis.mandataire?.nom }} sous réserve de
          l'engagement de fournir excclusivement à
          {{ devisObject.data.devis?.partenaire?.nom }} les documents
          nécessaires à la valorisation des opérations au titre du dispositif
          des Certificats d'Economies d'Energie et sous réserve de la validation
          de l'éligibilité du dossier par
          {{ devisObject.data.devis?.partenaire?.nom }} puis par l'autorité
          administrative compétente. Le montant de cette contribution
          financiére, hors champ d'application de la TVA, est susceptible de
          varier en fonction des travaux effectivement réalisé et du volume des
          CEE attribués à l'opération et est estimé à 4 000,00 € (*)
        </p>
        <div></div>
        <p>
          Le client accepte que {{ onlineUser.entreprise.lib }} collecte et
          traite ses données à caractére personnel pour les besoins du dépot
          d'un dossier CEE sur le registre EMMY conformément aux dispositions
          réglementaires en vigueur et qu'il communique ces données à
          {{ devisObject.data.devis?.partenaire?.nom }} à des fins de controle
          de la conformité des operations réalisées chez le client
          {{ devisObject.data.devis?.partenaire?.nom }}
          s'engage à respecter la réglementation française et européenne
          relative à la protection des données à caractére personnel Le
          bénéficiaire renconnait avoir pris connaissance et accepter les
          conditions générales de vente figurant ci-jointes.
        </p>
        <div>
          <div class="cadre row">
            <div class="col-8">
              <div>
                Apposer signature précédée de la mention
                <b>"Bon pour accord"</b>
              </div>
              <div>Le :</div>
            </div>
            <div class="col-4 p-2">
              <div class="row">
                <div class="col-8">Total H.T</div>
                <div class="col-4">
                  {{ devisObject.data.total_HT }}
                </div>
              </div>
              <div class="row">
                <div class="col-8">Total TVA</div>
                <div class="col-4">
                  {{ devisObject.data.total_TVA }}
                </div>
              </div>
              <div class="row">
                <div class="col-8"><b>Total TTC</b></div>
                <div class="col-4">
                  {{ devisObject.data.total_TTC }}
                </div>
              </div>
              <div class="row">
                <div class="col-8">PrimeCEE *</div>
                <div class="col-4">
                  {{ devisObject.data.devis.montant_cee }} €
                </div>
              </div>
              <div class="row">
                <div class="col-8">Reste à payer</div>
                <div class="col-4">{{ rest }} €</div>
              </div>
            </div>
          </div>

          <div>
            Moyens paiement :
            <b>{{ devisObject.data?.devis?.moyens_paiement }}</b>
          </div>

          <div class="d-flex justify-content-center">
            {{ onlineUser.entreprise.lib }} -
            {{ devisObject.data.devis.entreprise.rue }} - au capital de
            {{ devisObject.data.devis.entreprise.cp }} Siret :{{
              devisObject.data.devis.entreprise.siret
            }}
            - N° {{ devisObject.data.devis.entreprise.num_tva }}
          </div>
          <div class="d-flex justify-content-center">
            {{ devisObject.data.devis.entreprise.phone }} Courriel :
            {{ devisObject.data.devis.entreprise.email }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domains } from "@/environment";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      signedLoader: null,
      downloadLoader: false,
      pdfInfo: null,
      rest: null,
      downloadUrl: domains.download,
      editLoader: false,
      devisObject: {
        data: {
          devis: {
            brouillon: 1,
            product_to_delete: [],
            groups_to_add: [
              {
                type_traveaux_id: 1,
                type_piece_id: 1,
                piece: "ss",
                traveaux: "FIC",
                produits: [],
              },
            ],
          },
        },
      },
      devisObjectLoaded: false,
    };
  },
  computed: {
    ...mapGetters(["devisObjectObject", "getOnlineUser"]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
    isClient() {
      return this.getOnlineUser.role == "user.client";
    },
    onlineUser() {
      return this.getOnlineUser;
    },
  },

  methods: {
    ...mapActions([
      "updatedevis",
      "getonedevis",
      "downloadDevis",
      "email_devis",
      "signed_devis",
      "downloadDevis",
      "email_devis",
    ]),

    calculeRest() {
      return (
        this.devisObject.data.devis.montant_cee -
        this.devisObject.data.total_TTC
      );
    },
    sendEmail() {
      this.email_devis(this.devisObject.data.devis.id)
        .then(() => {
          this.box = "";
          this.$bvModal.msgBoxOk(this.$t("INVOICE_SENDED"), {
            title: "Confirmation",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            centered: true,
          });
        })
        .catch(() => {});
    },
    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("YOUSIGN_SENDED_WARNIN")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.signed_devis({
              id: this.devisObject.data.devis.id,
              doc: this.devisObject.data.id,
            })
              .then(() => {
                this.box = "";
                this.$bvModal.msgBoxOk(this.$t("YOUSIGN_SENDED"), {
                  title: "Confirmation",
                  size: "sm",
                  buttonSize: "sm",
                  okVariant: "success",
                  headerClass: "p-2 border-bottom-0",
                  centered: true,
                });
              })
              .catch(() => {
                this.erreur = this.getErreur;
              });
          }
        });
    },
    generate(devis) {
      this.downloadLoader = true;
      var id = 0;
      if (!devis) {
        id = this.devisObject.data.devis.id;
      } else {
        id = devis.devis.id;
      }
      this.downloadDevis(id)
        .then((response) => {
          this.downloadLoader = false;
          this.pdfInfo = response;
          this.download();
        })
        .catch(() => {
          this.downloadLoader = false;
        });
    },
    async signed() {
      if (this.devisObject.data.is_signed != "pending") {
        this.signedLoader = true;
        await this.signed_devis({
          id: this.devisObject.data.devis.id,
          doc: this.devisObject.data.id,
        })
          .then(() => {
            this.box = "";
            this.signedLoader = false;
            this.$bvModal.msgBoxOk(this.$t("YOUSIGN_SENDED"), {
              title: "Confirmation",
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              headerClass: "p-2 border-bottom-0",
              centered: true,
            });
          })
          .catch(() => {
            this.signedLoader = false;
            this.erreur = this.getErreur;
          });
      } else {
        this.showMsgBox();
      }
    },
    handleUpdate() {
      this.editLoader = true;

      this.getonedevis(this.devisObject.data.devis.id)
        .then(() => {
          this.editLoader = false;
          this.$router.push({
            path: `/devis/BAR-TH-101/${this.devisObject.data.devis.id}`,
          });
        })
        .catch(() => {
          this.editLoader = false;
        });
    },

    download() {
      if (this.pdfInfo.document.is_signed == "stored")
        window.open(this.pdfInfo.pdf, "_blank");
      else window.open(this.downloadUrl + this.pdfInfo.pdf, "_blank");
    },

    async fetchDevisObject(id) {
      try {
        this.devisObject = await this.getonedevis(id);
        if (this.devisObject.data && this.devisObject.data.devis) {
          this.devisObject.data.devis.brouillon = 1;
        }

        this.rest = this.calculeRest();

        this.devisObjectLoaded = true;
      } catch (error) {
        console.error("Failed to fetch devis object:", error);
      }
    },

    async handleRouteChange() {
      const id = this.$route.params.id;

      if (id) {
        try {
          this.editLoader = true;
          await this.fetchDevisObject(id);

          if (this.devisObject && Object.keys(this.devisObject).length > 0) {
            this.editLoader = false;
          } else {
            this.editLoader = true;
          }
        } catch (error) {
          console.error("Failed to update devisObject on route change:", error);
        }
      } else {
        this.loadSavedDevisObject();
        if (this.devisObject && Object.keys(this.devisObject).length > 0) {
          this.editLoader = false;
        } else {
          this.editLoader = true;
        }
      }
    },
  },
  created() {
    this.editLoader = true;

    this.handleRouteChange();
  },
};
</script>

<style lang="scss" scoped>
.custom-dropdown .dropdown-menu {
  box-shadow: none !important;
  margin-right: 50px;
  left: auto;
  right: 0;
}
.dropdown-menu.show {
  display: block;
  margin-right: 120px;
}
.loding {
  height: 100% !important;
}
.brouillon {
  border-radius: 25px;
}
.bg-color {
  border-radius: 5px;

  background-color: white;
}
div {
  font-size: 13px;
}
.divborder {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  color: #28367a;
  width: 100%;
}
.divborder::after,
.divborder::before {
  content: "";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  height: 2px;
  background-color: #000000;
}

.justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
b {
  color: #28367a;
}
.red {
  font-weight: bold;
  color: #e4261a;
}
.part {
  padding: 8px 0;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #515151;
    word-wrap: break-word;
  }
  .ml {
    margin-left: 500px;
  }
  .cadre {
    background-color: #f8f8f8;
    margin-top: 1%;
    margin-bottom: 1%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    padding: 13px;
    height: 350px;
  }
  .left {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 30%;
  }

  .right {
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
    width: 23%;
    p {
      float: right;
      padding: 8px;
      background-color: #d0cccc;
      color: #000;
      width: 50%;
      font-weight: 600;
      text-align: center;
      margin: 0 0 4px;
    }
  }

  .part-title {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }

  ul {
    list-style: none;

    li {
      @media only screen and (max-width: 1000px) {
        justify-content: space-between;
      }
      display: flex;
      align-items: center;
      color: #515151;

      span {
        display: block;
        width: 174px;
        font-size: 14px;
        white-space: nowrap;
      }

      .title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
        color: #393939;
        font-weight: 600;
        font-size: 14px;
        margin-right: 10px;
      }
    }

    .traveauxtitle {
      @media only screen and (max-width: 900px) {
        padding: 0;
      }
      padding: 0 15px;
    }
  }
  .half-width {
    flex: 0 0 25%;
  }
  .flexEnd {
    display: flex;
    justify-content: space-between;
    .sousTraitant {
      p {
        color: #2167d9;
      }
    }
    .subtable {
      .complet {
        @media only screen and (max-width: 1000px) {
          margin-right: 10%;
          width: 100%;
        }
        border-collapse: separate;
        margin-right: 10%;
        width: 100%;
        height: 32px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        tr {
          height: 24px;
        }

        .thead {
          @media only screen and (max-width: 1000px) {
            padding: 6px !important;
          }
          background-color: #6472b3;
          color: #fff;
          max-width: 118px;
          height: 32px;
        }
        td {
          color: #000;
          background-color: #e9eaf2;
          width: 118px;
        }
      }
    }
  }
  .tabledonne {
    overflow-x: auto;
    margin-bottom: 4px;
  }
  table {
    border-collapse: separate;
    th {
      font-weight: 400;
      font-size: 12px;
      background-color: #6472b3;
      padding: 4px;
      color: #fff;
      border: 1px solid #6472b3;
      text-align: center;
    }
    td {
      text-align: center;
      padding: 4px;
      background-color: #e9eaf2;
      .libblock {
        font-weight: 800;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    tr {
      height: 24px;
    }
  }
}
.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}
.groupEdit {
  border: 1px solid #707070;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 16px;
  box-shadow: 0 0 2px 0 #707070;

  .entete {
    font-weight: 400;
    font-size: 12px;
    background-color: #28367a;
    padding: 4px;
    color: #fff;
    text-align: center;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
  }
  .action {
    display: flex;
    align-content: center;
    .trash,
    .ajout {
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6px;
      color: #fff;
      height: 30px;
      width: 40%;
      margin: auto auto 15px auto;
    }
    .trash {
      background-color: #e4261a;
    }
  }
}

.textstyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
